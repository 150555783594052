import React, { useContext } from "react";
import {
  RoadmapContainer,
  RoadmapContent,
  RoadmapLine,
  RoadmapList,
  RoadmapListItem,
  RoadmapListMobile,
} from "./styled";
import { TextCommon, WrapperCommon } from "../../../styled";
import TitleCommon from "../../../Component/Title";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { ContextProviderWrapper } from "../../../Component/Context";

const Roadmap = () => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  return (
    <RoadmapContainer id="roadmap">
      <WrapperCommon>
        <div data-aos="flip-up">
          <TitleCommon text="Roadmap" />
          <TextCommon>
            Welcome to the roadmap of WICCHAIN, where we chart our course
            through innovative blockchain technology development and strategic
            market expansion.
          </TextCommon>
        </div>
        <RoadmapContent data-aos="zoom-in">
          <RoadmapLine></RoadmapLine>
          {isDesktop ? (
            <RoadmapList>
              {roadmap.map((item: any, index: number) => (
                <RoadmapListItem key={index}>
                  <p className="year">{item.year}</p>
                  <h3 className="title">{item.title}</h3>
                  {item.subList.map((inner: any, index: number) => (
                    <p key={index} className="text">
                      {inner.text}
                    </p>
                  ))}
                </RoadmapListItem>
              ))}
            </RoadmapList>
          ) : (
            <RoadmapListMobile>
              <Swiper
                slidesPerView={2}
                spaceBetween={10}
                centeredSlides={true}
                grabCursor={true}
                initialSlide={1}
                pagination={{
                  clickable: true,
                }}
                className="swiper-mobile"
              >
                {roadmap.map((item: any, index: number) => (
                  <SwiperSlide className="asdas">
                    <RoadmapListItem key={index}>
                      <p className="year">{item.year}</p>
                      <h3 className="title">{item.title}</h3>
                      {item.subList.map((inner: any, index: number) => (
                        <p key={index} className="text">
                          {inner.text}
                        </p>
                      ))}
                    </RoadmapListItem>
                  </SwiperSlide>
                ))}
              </Swiper>
            </RoadmapListMobile>
          )}
        </RoadmapContent>
      </WrapperCommon>
    </RoadmapContainer>
  );
};

const roadmap = [
  {
    year: "Q1, 2025",
    title: "Start Applying AI",
    subList: [
      {
        text: "Apply AI to create Chainlink, integrate EVM and A Tom Parachain to optimise Gas costs.",
      },
    ],
  },
  {
    year: "Q2, 2025",
    title: "AI With Social & Game FI",
    subList: [
      {
        text: "Apply AI to Social FI & game FI, Burn Wic",
      },
    ],
  },
  {
    year: "Q3, 2025",
    title: "AI With Social Network",
    subList: [
      {
        text: "Apply AI to Wic Chain's social network (Tiktok, Facebook, X, Instagram)",
      },
    ],
  },
  {
    year: "Q4, 2025",
    title: "Listing CEX",
    subList: [
      {
        text: "Launch of Wic Chain's dedicated Exchange platform (Wic Exchange), Wic Airdrops, and listing on the top two exchanges MEXC & Gate.io.",
      },
    ],
  },
];

export default Roadmap;
