import styled from "styled-components";
import { TextCommon, WrapperCommon } from "../../../styled";
import { TitleContainer } from "../../../Component/Title/styled";

export const RoadmapContainer = styled.div`
  padding-top: 150px;
  position: relative;
  z-index: 5;
  ${TitleContainer} {
    text-align: center;
  }
  ${TextCommon} {
    width: 50%;
    margin: 0 auto 44px;
    @media screen and (max-width: 1024px) {
      width: unset;
      margin-bottom: 10px;
    }
  }
`;
export const RoadmapContent = styled.div`
  position: relative;
  min-height: 538px;
  @media screen and (max-width: 1024px) {
    min-height: unset;
  }
`;
export const RoadmapLine = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: solid 1px rgba(255, 255, 255, 0.5);
  width: 100%;
  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 60px;
    height: 60px;
    top: 50%;
    transform: translateY(-30px);
    background: center / contain no-repeat;
  }
  &:before {
    left: -30px;
    background-image: url("/assets/images/Roadmap/icon_cirle_left.png");
  }
  &:after {
    right: -30px;
    background-image: url("/assets/images/Roadmap/icon_cirle_right.png");
  }
  @media screen and (max-width: 1024px) {
    top: 20px;
    transform: translateY(0);
    width: calc(100% + 24px);
    margin-left: -12px;
    &::before,
    &::after {
      content: none;
    }
  }
`;

export const RoadmapList = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: flex-start;
`;
export const RoadmapListItem = styled.div`
  width: 350px;
  border-radius: 15px;
  position: relative;
  z-index: 4;
  padding: 12px;
  min-height: 215px;
  &:before {
    border-radius: 15px;
    opacity: 0.08;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background: url("/assets/images/Roadmap/roadmap_bg.png") lightgray 50% /
      cover no-repeat;
    mix-blend-mode: hard-light;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  &:after {
    position: absolute;
    content: "";
    bottom: -70px;
    left: 50%;
    transform: translateX(-50%);
    background: url("/assets/images/Roadmap/icn_dot.svg") center / contain
      no-repeat;
    width: 36px;
    height: 36px;
    transition: opacity .26s ease;
  }
  .year {
    border-radius: 10.25px;
    border: 1.281px solid var(--stroke-25-button, rgba(255, 255, 255, 0.4));
    background: rgba(248, 248, 248, 0.2);
    background-blend-mode: luminosity;
    box-shadow: 0px 5.125px 30.75px 0px rgba(0, 0, 0, 0.3);
    text-align: center;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 12px;
    padding: 9px 0;
  }
  .title {
    background: var(
      --GRA,
      linear-gradient(270deg, #fedf7a 0%, #ecb131 27.45%, #ffff8f 100%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .text {
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }
  &:nth-child(2) {
    bottom: -325px;
    &:after {
      bottom: unset;
      top: -74px;
    }
  }
  &:nth-child(4) {
    bottom: -325px;
    &:after {
      bottom: unset;
      top: -74px;
    }
  }
  @media screen and (max-width: 1024px) {
    max-width: 100%;
    background: rgba(57, 57, 57, 0.53);
    margin-top: 60px;
    min-height: 395px;
    &:after {
      bottom: unset;
      top: -57px;
      opacity: 0;
    }
    &:nth-child(2) {
      bottom: unset;
    }
  }
`;
export const RoadmapListMobile = styled.div`
  @media screen and (max-width: 1024px) {
    .swiper-mobile {
      margin-left: -60px;
      margin-right: -60px;
    }
    .swiper-slide.swiper-slide-active {
      ${RoadmapListItem} {
        &:after {
          opacity: 1;
        }
      }
    }
  }
`;